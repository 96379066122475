<template>
  <div class="tabs-radio tabs-radio--toggle">
    <label class="radio">
      <input
        :id="`radio_${uniqComponentId}`"
        type="checkbox"
        v-model="value"
        v-bind="$attrs"
      />
      <label :for="`radio_${uniqComponentId}`" class="radio__label">{{
        label
      }}</label>
    </label>
    <SmoothHeight>
      <div class="radio-btns__text" v-if="value === true">
        Напиши в телеграм бот
        <a href="https://t.me/liga_forum_support" target="_blank">
          @liga_forum_support
        </a>
        и мы добавим ваше образовательное учреждение
      </div>
    </SmoothHeight>
  </div>
</template>

<script>
import uniqueString from "unique-string";
import SmoothHeight from "@/components/SmoothHeight.vue";

export default {
  name: "RadioBtnToggle",
  components: { SmoothHeight },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    uniqComponentId() {
      return uniqueString();
    },
  },
  props: {
    modelValue: Boolean,
    university: Boolean,
    text: String,
    label: String,
  },
};
</script>

<style scoped></style>
